export const SUCCESS_STATUS = 0;
export const PMPP_BASE_URL =
  process.env.VUE_APP_PMPP_BASE_URL || window.location.origin;

export const candidateWinnerDict: IRecord = {
  0: '未中标',
  1: '第一中标候选人',
  2: '第二中标候选人',
  3: '第三中标候选人',
};
