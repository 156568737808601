import {
  computed,
  defineComponent,
  watch,
  type PropType,
  ref,
  inject,
} from 'vue';
import { ApiPageFrameResponse } from '@/api/type';
import Footer from '@/components/homepage/footer';
import { MACHINE } from '@/store/machine/const';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'HomepageFooter',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object as PropType<
        ApiPageFrameResponse['footer'] & { platformName?: string }
      >,
      default: () => ({}),
    },
  },
  setup(props) {
    const { state }: IRecord = useStore();

    const footerDataN = ref<IRecord>({});
    const isCanvasReadonly = inject('isCanvasReadonly', false);

    const footerHeight = ref(0);

    const getFooterData = computed(() => state[MACHINE].footerWidgetData);

    watch(
      () => getFooterData,
      (newVal) => {
        footerDataN.value = newVal.value;
        if (newVal?.value.layout?.h) {
          footerHeight.value = newVal.value?.layout.h * 30; // 30是grid-layout的row-height配置项
        }
      },
      { immediate: true, deep: true }
    );

    return () => (
      <>
        {footerHeight.value > 0 && (
          <Footer
            style={{ height: footerHeight.value + 'px' }}
            isCanvasReadonly={isCanvasReadonly}
            data={footerDataN.value}
          ></Footer>
        )}
      </>
    );
  },
});
