import { defineComponent, ref, watch, computed, inject } from 'vue';
import Header from '@/components/homepage/header';
import Navigation from '@/components/homepage/Navigation';
// import { add } from 'stickyfilljs';
import './style.less';
import { useStore } from 'vuex';
import { MACHINE } from '@/store/machine/const';

export default defineComponent({
  name: 'HomepageHeader',
  props: {
    isCanvasReadonly: {
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
      default: () => ({}),
      type: Object,
    },
  },
  setup(props) {
    const { state }: IRecord = useStore();

    const isCanvasReadonly = inject('isCanvasReadonly', false);

    const navWgtData = computed(() => state[MACHINE].navBarData);

    const headerWgtData = computed(() => state[MACHINE].headerWidgetData);

    const headerHeight = ref(0);

    const navHeight = ref(0);

    watch(
      () => navWgtData,
      (nv) => {
        if (nv.value?.layout?.h) {
          navHeight.value = nv.value.layout.h * 30; // 30是grid-layout的row-height配置项
        }
      },
      { immediate: true, deep: true }
    );

    watch(
      () => headerWgtData,
      (nv) => {
        if (nv.value?.layout?.h) {
          headerHeight.value = nv.value.layout.h * 30; // 30是grid-layout的row-height配置项
        }
      },
      { immediate: true, deep: true }
    );

    return () => (
      <div style={{ position: 'sticky', top: 0, zIndex: 999 }}>
        {headerHeight.value > 0 && (
          <Header
            isCanvasReadonly={isCanvasReadonly}
            data={headerWgtData.value}
            style={{ height: `${headerHeight.value}px` }}
          />
        )}

        {navHeight.value > 0 && (
          <Navigation
            isCanvasReadonly={isCanvasReadonly}
            data={navWgtData.value}
            style={{ height: `${navHeight.value}px` }}
          />
        )}
      </div>
    );
  },
});
