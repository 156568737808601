/*
 * @Author: limit
 * @Date: 2023-07-17 17:44:59
 * @LastEditTime: 2023-07-26 17:28:35
 * @LastEditors: limit
 * @FilePath: \portal-web\src\main.ts
 * @Description: 由limit创建！
 */
import { createApp, watch } from 'vue';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import '@/style/theme.less';
import 'normalize.css/normalize.css';
import '@/style/index.less';
import '@/router/guard';
import VueGridLayout from 'vue-grid-layout';
// import { ElLoading } from 'element-plus';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/theme-chalk/index.css';

const app = createApp(App);
app
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .use(VueGridLayout);

app.mount('#app');
