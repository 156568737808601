import request from '@/util/http';
import type { ApiDocPagesRequest } from './type';

// 左侧树接口
export const getPageFrameData = (): Promise<ResponseResult> =>
  request.get('/v9/portal/conf/template/std/frame');

// 查询门户模版列表
export const getTemplateData = (): Promise<ResponseResult> =>
  request.get('/v9/ec/conf/template/std/list');

// 门户模板删除
export const delectTemplate = (params: IRecord): Promise<ResponseResult> =>
  request.post('/v9/ec/conf/template/std/delete', params);

// 门户模板使用 (保存)
export const useTemplate = (params: IRecord): Promise<ResponseResult> =>
  request.post('/v9/ec/conf/template/std/use', params);

// 门户模板复制
export const copyTemplate = (params: IRecord): Promise<ResponseResult> =>
  request.post('/v9/ec/conf/template/std/copy', params);

// 门户首页，门户二级页面列表数据加载
export const getDocPagesByType = (
  data: ApiDocPagesRequest
): Promise<ResponseResult> =>
  request.post('/v9/ec/cms/document/std/docPages', data);

// 获取自定义接口 数据
export const customRequest = (
  url: string,
  params?: IRecord
): Promise<ResponseResult> => request.get(url, { params });

// 门户页面框架
export const getPageFrameworkData = (): Promise<ResponseResult> =>
  request.get('/v9/portal/conf/template/std/frame');

export const getMyCompnt = (): Promise<ResponseResult> =>
  request.get('/v9/ec/conf/comp/list');

export const getMyNav = (data: {
  templateId: string;
}): Promise<ResponseResult> =>
  request.get(`/v9/ec/conf/template/std/myNavis?templateId=${data.templateId}`);

// 页面组件加载-主页画布-设计器使用
export const getCanvas = (params: IRecord): Promise<ResponseResult> =>
  request.get('/v9/portal/conf/template/std/home/canvas/design', { params });

// 获取footer组件信息
export const getFooterInfo = (params: IRecord): Promise<ResponseResult> =>
  request.get('/v9/portal/widget/std/footerInfo', { params });

export const getProcTypeDy = (): Promise<ResponseResult> =>
  request.get('/v9/biding/conf/std/dynamic-procType');
