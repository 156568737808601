import { MachineState } from './../types';

export default {
  SET_BTN_LOADING(state: MachineState, payLoad: boolean) {
    state.btnLoading = payLoad;
  },
  updateMachine(state: IRecord, playLoad: IRecord) {
    Object.assign(state, playLoad);
  },
};
