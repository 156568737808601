/*
 * @Author: limit
 * @Date: 2023-07-17 17:45:00
 * @LastEditTime: 2023-07-25 17:19:35
 * @LastEditors: limit
 * @FilePath: \portal-web\src\util\http.ts
 * @Description: 由limit创建！
 */

import { MACHINE } from '@/store/machine/const';
import axios, { AxiosStatic } from 'axios';
import { ElMessage } from 'element-plus';

// 设置header请求类型
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.Authorization = localStorage.getItem('token')
  ? localStorage.getItem('token')
  : '';
axios.defaults.withCredentials = true;
// 创建axios实例
axios.defaults.baseURL = process.env.VUE_APP_REQUEST_URL; // 自定义接口
axios.defaults.timeout = 30000;

export const httpHeaders = {
  // Authorization: 'bearer cn-740ca9cc-767d-446e-a2c9-56e00d58eec0-ut', // 供应商1
  // Authorization: 'bearer cn-0d8c4464-5112-4025-8d22-1010383349dc-ut', // 采购商
  // 'x-product-code': 'scm_gsrm-v9-app',
  // 'x-org-id': '750704084747264',
  // 'x-participant-id': '123',
};

const request = axios.create();
// request拦截器
request.interceptors.request.use(
  (config) => {
    // 请求头的token参数，暂时不用，为以后预留
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);
// 响应拦截器
request.interceptors.response.use(
  (res) => {
    if (res.data instanceof Blob) return res;
    // 暂时不做任何响应拦截，获取到响应就直接返回
    return res.data;
  },
  (error) => {
    let { message } = error;
    if (message === 'Network Error') {
      message = '后端接口连接异常';
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时';
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常';
    }
    ElMessage({
      type: 'error',
      message: message,
    });
    return Promise.reject(error);
  }
);

(request as AxiosStatic).all = axios.all;
(request as AxiosStatic).spread = axios.spread;

export default request;
