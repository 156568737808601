import { defineComponent, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useAsyncState } from '@vueuse/core';
import { customRequest } from '@/api/editTemplate';
import { SUCCESS_STATUS } from '@/util/constants';
import { ElMessage } from 'element-plus';
import './style.less';

export default defineComponent({
  name: 'HomepageFooter',
  props: {
    isCanvasReadonly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    // 因为页尾的数据很多地方用, 这个接口也提到App.js 里了
    const { state: pageData, execute } = useAsyncState<IRecord>(
      async () =>
        await customRequest(props.data.api, {
          templateId: route.query.templateId,
          source: props.isCanvasReadonly ? 'preview' : '',
        }).then((result: IRecord) => {
          if (result.status === SUCCESS_STATUS) {
            return result.data || [];
          }
          ElMessage.error(result.message || '获取页尾数据失败！');
          return [];
        }),
      {}
    );

    watch(
      () => props.data,
      () => {
        // execute();
      },
      { immediate: true, deep: true }
    );

    watch(
      () => pageData,
      (nv) => {
        if (nv.value?.quickPathGroups?.length > 0) {
          nv.value.quickPathGroups.sort(
            (a: IRecord, b: IRecord) => Number(a.sort) - Number(b.sort)
          );
          for (const group of nv.value.quickPathGroups) {
            if (group?.naviDTOS?.length > 0) {
              group?.naviDTOS.sort(
                (a: IRecord, b: IRecord) => Number(a.sort) - Number(b.sort)
              );
            }
          }
        }
      },
      { immediate: true, deep: true }
    );

    return () => (
      <div
        class={
          props.isCanvasReadonly
            ? 'homepage-footer-container ov-hidden'
            : 'homepage-footer-container'
        }
      >
        <div class="portal-app-content">
          <div class="footer-container">
            {pageData.value.footerInfo && (
              <div class="footer-company-title d-flex align-items-center">
                {pageData.value.footerInfo.useDefaultLogo && (
                  <img
                    style={{ marginTop: '30px', height: '30px' }}
                    class="footer-company-logo"
                    src={require('@/assets/portal/footer.png')}
                  />
                )}
                {!pageData.value.footerInfo.useDefaultLogo && (
                  <img
                    style={{ marginTop: '30px' }}
                    class="footer-company-logo"
                    src={pageData.value.footerInfo.logoPath}
                  />
                )}
                {pageData.value.footerInfo.platformName && (
                  <p class="footer-company-name">
                    {pageData.value.footerInfo.platformName}
                  </p>
                )}
              </div>
            )}
            {pageData.value.quickPathGroups && (
              <div class="d-flex justify-content-between">
                <div class="footer-company-info">
                  <p
                    class="footer-company-info-item"
                    v-show={pageData.value.footerInfo.addr}
                  >
                    联系地址：<span>{pageData.value.footerInfo.addr}</span>
                  </p>
                  <p
                    class="footer-company-info-item"
                    v-show={pageData.value.footerInfo.phone}
                  >
                    联系电话：<span>{pageData.value.footerInfo.phone}</span>
                  </p>
                  <p
                    class="footer-company-info-item"
                    v-show={pageData.value.footerInfo.email}
                  >
                    企业邮编：<span>{pageData.value.footerInfo.email}</span>
                  </p>
                  <p
                    class="footer-company-info-item"
                    v-show={pageData.value.footerInfo.icp}
                  >
                    {pageData.value.footerInfo.icpShowIcon && (
                      <a
                        href="https://beian.miit.gov.cn"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require('@/assets/icp.png')}
                          class="icp_img"
                        ></img>
                      </a>
                    )}
                    <a
                      href="https://beian.miit.gov.cn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {pageData.value.footerInfo.icp}
                    </a>
                  </p>
                  <p
                    class="footer-company-info-item"
                    v-show={pageData.value.footerInfo.copyRight}
                  >
                    {pageData.value.footerInfo.copyRight}
                  </p>
                  <p
                    class="footer-company-info-item"
                    v-show={pageData.value.footerInfo.techSupport}
                  >
                    技术支持：
                    <span>{pageData.value.footerInfo.techSupport}</span>
                  </p>
                  <p
                    class="footer-company-info-item"
                    v-show={pageData.value.footerInfo.techSupportUrl}
                  >
                    技术支持网址：
                    <span>{pageData.value.footerInfo.techSupportUrl}</span>
                  </p>
                </div>
                {pageData.value.quickPathGroups?.map((item: IRecord) => (
                  <>
                    {(item.naviDTOS?.length || item.name) && (
                      <div class="footer-links-nav">
                        <h5 class="footer-links-nav-tit">{item.name}</h5>
                        <ul class="footer-links-nav-con">
                          {item.naviDTOS?.map((link: IRecord, idx: number) => (
                            <>
                              {idx < 3 && (
                                <li>
                                  <span
                                    class="footer-text-link"
                                    onClick={() => {
                                      if (link.linkUrl?.indexOf('http') > -1) {
                                        window.open(link.linkUrl, '_blank');
                                      } else {
                                        router.push(link.linkUrl);
                                      }
                                    }}
                                  >
                                    {link.label}
                                  </span>
                                </li>
                              )}
                            </>
                          ))}
                          {item.naviDTOS?.length > 3 && (
                            <li>
                              <el-popover
                                placement="bottom"
                                v-slots={{
                                  reference: () => (
                                    <span style={{ color: '#fff' }}>
                                      {' '}
                                      更多&gt;&gt;
                                    </span>
                                  ),
                                  default: () => (
                                    <>
                                      {item.naviDTOS?.map(
                                        (link: IRecord, idx: number) => (
                                          <>
                                            {idx > 2 && (
                                              <li>
                                                <span
                                                  class="footer-text-link"
                                                  onClick={() => {
                                                    if (
                                                      link.linkUrl?.indexOf(
                                                        'http'
                                                      ) > -1
                                                    ) {
                                                      window.open(
                                                        link.linkUrl,
                                                        '_blank'
                                                      );
                                                    } else {
                                                      router.push(link.linkUrl);
                                                    }
                                                  }}
                                                >
                                                  {link.label}
                                                </span>
                                              </li>
                                            )}
                                          </>
                                        )
                                      )}
                                    </>
                                  ),
                                }}
                              ></el-popover>
                            </li>
                          )}
                        </ul>
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
});
