/*
 * @Author: limit
 * @Date: 2023-07-17 17:45:00
 * @LastEditTime: 2023-07-17 18:27:41
 * @LastEditors: limit
 * @FilePath: \portal-web\src\store\index.ts
 * @Description: 由limit创建！
 */
import { Store, createStore, useStore as baseUseStore } from 'vuex';
import machine from './machine';
import { MachineState } from './types';
import { InjectionKey } from 'vue';

export interface GlobalState {
  machine: MachineState;
}

export const key: InjectionKey<Store<GlobalState>> = Symbol('storeKey');

export default createStore<GlobalState>({
  modules: {
    machine
  },
});

// 定义自己的 `useStore` 组合式函数
export function useStore() {
  return baseUseStore(key);
}
