import request from '@/util/http';

export async function downloadFile(fileId: string, headers: IRecord) {
  return request({
    url: `/v9/spt/file/api/download/${fileId}/url?tenantId=${headers['x-tenant-id']}&productCode=${headers['x-product-code']}&orgId=${headers['x-org-id']}`,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      ...headers,
    },
  });
}
// 预览
export async function previewFile(fileId: string, headers: IRecord) {
  return request({
    url: `/v9/spt/file/api/preview/${fileId}/url?tenantId=${headers['x-tenant-id']}&productCode=${headers['x-product-code']}&orgId=${headers['x-org-id']}`,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      ...headers,
    },
  });
}
export const getPortalRegisterUrl = (): Promise<ResponseResult> =>
  request.get('v9/supplier/register/seller/std/getPortalRegisterUrl');

export const getWorkBenchUrl = (): Promise<ResponseResult> =>
  request.get('v9/portal/widget/std/getWorkbenchUrl');
// 获取用户信息
export const getBidUrl = (params: IRecord): Promise<ResponseResult> =>
  request.get('/v9/portal/conf/bid/std/getBidUrl', { params });
export const getLogoutUrl = (params?: IRecord) => {
  return request.post('/v9/base/oauth/pmpp/getLogoutUrl', { params });
};
