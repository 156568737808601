export const BID_ANN_CONFIG = {
  RULE_TYPES: [
    {
      value: 'bid_deadline,desc;publish_date,desc',
      name: '按任务状态+发布时间倒序',
    },
    {
      value: 'publish_date,desc',
      name: '按发布时间倒序',
    },
    {
      value: 'bid_deadline,asc',
      name: '按截止时间正序',
    },
  ],
};
export const BID_WIN_CONFIG = {
  RULE_TYPES: [
    {
      value: 'publish_date,desc',
      name: '按发布时间倒序',
    },
    {
      value: 'end_date,asc',
      name: '按截至时间正序',
    },
  ],
};
export const NOTICE_WIDGET_DATA = [
  {
    sort: '',
    prop: 'objType',
    label: '招标类型',
    display: false,
    sysName: '招标类型',
    customName: '招标类型',
  },
  {
    sort: 1,
    prop: 'title',
    label: '招标名称',
    display: true,
    sysName: '招标名称',
    customName: '招标名称',
    hasLink: true,
    linkUrl: '/portal/tender-notice/{docId}',
  },
  {
    sort: '',
    prop: 'stateName',
    label: '任务状态',
    display: false,
    sysName: '任务状态',
    customName: '任务状态',
  },
  {
    sort: '',
    prop: 'operate',
    label: '操作按钮',
    display: false,
    sysName: '操作按钮',
    customName: '操作按钮',
  }, // 原来没有
  {
    sort: '',
    prop: 'procUnitName',
    label: '招标单位',
    display: false,
    sysName: '招标单位',
    customName: '招标单位',
  },
  {
    sort: '',
    prop: 'orgName',
    label: '需求单位',
    display: false,
    sysName: '需求单位',
    customName: '需求单位',
  },
  {
    sort: '',
    prop: 'projectName',
    label: '项目名称',
    display: false,
    sysName: '项目名称',
    customName: '项目名称',
  },
  {
    sort: '',
    prop: 'code',
    label: '招标编号',
    display: false,
    sysName: '招标编号',
    customName: '招标编号',
  },
  {
    sort: '',
    prop: 'linkMan',
    label: '联系人',
    display: false,
    sysName: '联系人',
    customName: '联系人',
  },
  {
    sort: '',
    prop: 'phone',
    label: '联系电话',
    display: false,
    sysName: '联系电话',
    customName: '联系电话',
  },
  {
    sort: 2,
    prop: 'publishDate',
    label: '发布日期',
    display: true,
    sysName: '发布日期',
    customName: '发布日期',
  },
  {
    sort: 3,
    prop: 'bidDeadline',
    label: '截止时间',
    display: true,
    sysName: '截止时间',
    customName: '截止时间',
  },
  {
    sort: '',
    prop: 'changeDate',
    label: '变更时间',
    display: false,
    sysName: '变更时间',
    customName: '变更时间',
  },
  {
    sort: '',
    prop: 'email',
    label: '联系邮箱',
    display: false,
    sysName: '联系邮箱',
    customName: '联系邮箱',
  },
];

export const INFO_SEARCH_DATA = [
  {
    prop: 'title',
  },
  {
    prop: 'link',
  },
  {
    prop: 'icon',
  },
];
export const NEWS_WIDGET_CONFIG = {
  RULE_TYPES: [
    {
      value: 'publish_date,desc',
      name: '按发布时间倒序',
    },
    {
      value: 'publish_date,asc',
      name: '按发布时间正序',
    },
  ],
  COLUMNS: [
    {
      customName: '标题',
      display: true,
      field: 'title',
      sort: 1,
      sysName: '标题',
      linkUrl: '/portal/news-information/{docId}',
    },
    {
      customName: '发布时间',
      display: false,
      field: 'publishDate',
      sort: 1,
      sysName: '发布时间',
    },
    {
      customName: '作者',
      display: false,
      field: 'author',
      sort: 1,
      sysName: '作者',
    },
  ],
};

export const SUPPLIER_ENTRANCE_WIDGET = {
  ORIGINAL_COLUMNS: { text: '', thumbImg: '' },
};

export const MORE_LINK_MAP: IRecord = {
  notice_widget: '/portal/tender-notice?code=notice',
  news_widget: '/portal/news-information?code=news',
  pub_widget: '/portal/bid-publicity?code=pub',
  res_widget: '/portal/result-publish?code=res',
  download_widget: '/portal/file-download?code=download',
};
export const LINK_URL_MAP: IRecord = {
  notice_widget: '/portal/tender-notice/{docId}',
  news_widget: '/portal/news-information/{docId}',
  pub_widget: '/portal/bid-publicity/{docId}',
  res_widget: '/portal/result-publish/{docId}',
};

export const QUICK_NAV_TABLE_CONFIG = [
  {
    code: 'introduction',
    pathName: '关于我们',
    linkUrl: '/portal/about-us',
    isCheck: 0,
  },
  {
    code: 'help',
    pathName: '帮助中心',
    linkUrl: '/portal/help-center',
    isCheck: 0,
  },
];

export const NAV_TABLE_CONFIG = [
  {
    code: 'notice',
    pathName: '招标公告',
    linkUrl: '/portal/tender-notice',
    isCheck: 0,
  },
  {
    code: 'pub',
    pathName: '中标公示',
    linkUrl: '/portal/bid-publicity',
    isCheck: 0,
  },
  {
    code: 'res',
    pathName: '结果发布',
    linkUrl: '/portal/result-publish',
    isCheck: 0,
  },
  {
    code: 'news',
    pathName: '新闻资讯',
    linkUrl: '/portal/news-information',
    isCheck: 0,
  },
  {
    code: 'law',
    pathName: '政策法规',
    linkUrl: '/portal/policies-regulation',
    isCheck: 0,
  },
  {
    code: 'download',
    pathName: '资料中心',
    linkUrl: '/portal/file-download',
    isCheck: 0,
  },
  {
    code: 'dynamic',
    pathName: '行业动态',
    linkUrl: '/portal/industry-news',
    isCheck: 0,
  },
  {
    code: 'dynamic',
    pathName: '关于我们',
    linkUrl: '/portal/about-us',
    isCheck: 0,
  },
];
export const COMPS_TYPES = {
  HEADER_WIDGET: 'head',
  NAVIGATION: 'navigation',
  CAROUSEL_WIDGET: 'carousel_widget', // 轮播图 在其他页面
  NOTICE_WIDGET: 'notice_widget', // 招标公告
  NEWS_WIDGET: 'news_widget', // 新闻资讯
  PUB_WIDGET: 'pub_widget', // 中标公示
  PLATFORM_STAT: 'platform_stat', // 平台数据
  DOWNLOAD_WIDGET: 'download_widget', // 资料中心
  INFO_SEARCH: 'info_search_widget', // 信息查询
  LAW_WIDGET: 'law_widget', // 政策与法规
  SUPPLIER_ENTRANCE: 'supplier_entrance', // 供方入口
  EXCELLENT_SUPPLIER: 'excellent_supplier', // 优质供应商 在其他页面
  PLATFORM_PONIT_WIDGET: 'platform_point_widget', // 平台价值点
  FOOTER_WIDGET: 'footer', // 页脚  在其他页面
  RESULT_WIDGET: 'res_widget', // 结果发布
};

export const INFO_SEARCH = {
  ROW: {
    title: '',
    link: '',
    icon: '',
  },
};

export const META_CODE_MAP: IRecord = {
  notice_widget: 'notice',
  news_widget: 'news',
  pub_widget: 'pub',
  download_widget: 'download',
  res_widget: 'res',
};

export const USER_DEFINED = 'user_defined';

export const PORTAL_ROUTER_PATH = {
  // TODO 这里的key 要和树的value 对上
  HOMEPAGE: '/', // 首页
  notice_widget: '/portal/tender-notice', // 招标公告
  pub_widget: '/portal/bid-publicity', // 中标公示
  news_widget: '/portal/news-information', // 新闻资讯
  POLICIES_REGULATION: '/portal/policies-regulation', // 政策法规
  INDUSTRY_NEWS: '/portal/industry-news', // 行业动态
  ANNOUNCEMENT: '/portal/announcement', // 通知公告
  FILE_DOWNLOAD: '/portal/file-download', // 资料下载
  ABOUT_US: '/portal/about-us', // 关于我们
  HELP_CENTER: '/portal/help-center', // 帮助中心
  GLOBAL_SEARCH: '/portal/search', // 全局搜索
};

export const TREEID_PATH_MAP = {
  homepage: 'TODO',
};

export const PLATFORM_PONIT_WIDGET = {
  ORIGINAL_DATA: [
    {
      text: '行业背景',
      icon: {
        fileId: '',
        imagePreviewUrl: '',
      },
      isCheck: 0,
    },
    {
      text: '专业权威',
      icon: {
        fileId: '',
        imagePreviewUrl: '',
      },
      isCheck: 0,
    },
    {
      text: '优质资源',
      icon: {
        fileId: '',
        imagePreviewUrl: '',
      },

      isCheck: 0,
    },
    {
      text: '安全保障',
      icon: {
        fileId: '',
        imagePreviewUrl: '',
      },
      isCheck: 0,
    },
    {
      text: '信息安全',
      icon: {
        fileId: '',
        imagePreviewUrl: '',
      },
      isCheck: 0,
    },
  ],
};

export const defaultThemeColor: IRecord = {
  '--gld-color-primary': '#001a80',
  '--gld-color-primary-light': '#1b4cb8',
  '--gld-color-white': '#ffffff',
  '--gld-color-danger': '#cc0001',
  '--gld-disabled-bg-color': '#e5e5e5',
  '--gld-color-primary-light-9': '#e6eefa',
  '--gld-border-color': '#dadfe5',
  '--gld-nav-bg': '#001a80',
  '--gld-bg-color': '#f8fafb',
  '--gld-footer-bg': '#192638',
  '--gld-border-color-light': '#f2f3f5',
  '--gld-text-color': '#262626',
  '--gld-text-color-regular': '#595959',
  '--gld-text-color-secondary': '#8c8c8c',
  '--gld-text-color-placeholder': '#bfbfbf',
  '--gld-text-color-disabled': '#c0c4cc',
};
