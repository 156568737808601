import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import './style.less';

export default defineComponent({
  name: 'Navigation',
  props: {
    isCanvasReadonly: {
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
      default: () => ({}),
      type: Object,
    },
  },

  setup(props) {
    const router = useRouter();
    const route = useRoute();

    return () => (
      <div class="navigation-container">
        <nav class="nav-container portal-app-content">
          <ul class="nav-lists">
            <li
              class={{
                'nav-item': true,
                'nav-item--active': route.path === '/',
              }}
              onClick={() => {
                if (!props.isCanvasReadonly) {
                  router.push({
                    path: '/',
                  });
                }
              }}
            >
              首页
            </li>
            {props.data.meta?.__config__?.data
              ?.filter((item: IRecord) => item.type !== 'HOME')
              .map((nav: IRecord) => {
                return (
                  <li
                    class={{
                      'nav-item': true,
                      'nav-item--active':
                        route.path.indexOf(nav.linkUrl) !== -1,
                    }}
                    onClick={() => {
                      if (!props.isCanvasReadonly) {
                        if (nav.code === 'user_defined') {
                          window.open(nav.linkUrl, '_blank');
                        } else {
                          router.push({
                            path: nav.linkUrl,
                            query: {
                              code: nav.code,
                            },
                          });
                        }
                      }
                    }}
                  >
                    <span> {nav.pathName}</span>
                  </li>
                );
              })}
          </ul>
        </nav>
      </div>
    );
  },
});
