import { defineComponent, ref, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAsyncState } from '@vueuse/core';
import { customRequest } from '@/api/editTemplate';
import {
  getPortalRegisterUrl,
  getWorkBenchUrl,
  getLogoutUrl,
} from '@/api/homepageApi';
import { SUCCESS_STATUS } from '@/util/constants';
import { ElMessage } from 'element-plus';
import { Search } from '@element-plus/icons-vue';
import { useStore } from 'vuex';
import { MACHINE, SET_MACHINE } from '@/store/machine/const';
import { USER_DEFINED, PORTAL_ROUTER_PATH } from '../config';
import './style.less';

export default defineComponent({
  name: 'HomepageHeader',
  props: {
    isCanvasReadonly: {
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
      default: () => ({}),
      type: Object,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const keyword = ref('');

    const { state, dispatch }: IRecord = useStore();

    const quickBar = ref([]);

    watch(
      () => props.data,
      (newVal) => {
        if (newVal) {
          quickBar.value = props.data?.meta?.__config__?.data;
        }
      },
      { immediate: true, deep: true }
    );

    const { state: pageData, execute } = useAsyncState<IRecord>(
      async () =>
        await customRequest(props.data.api, {
          templateId: route.query.templateId,
          source: props.isCanvasReadonly ? 'preview' : '',
        }).then((result: IRecord) => {
          if (result.status === SUCCESS_STATUS) {
            return result.data || [];
          }
          ElMessage.error(result.message || '获取页头数据失败！');
          return [];
        }),
      {}
    );

    const handlePush = (nav: IRecord) => {
      if (nav.code === USER_DEFINED) {
        window.open(nav.linkUrl, '_blank');
      } else {
        router.push({
          path: nav.linkUrl,
          query: {
            code: nav.code,
          },
        });
      }
    };

    const getPortalRgstUrl = async () => {
      const { status, data, message } = await getPortalRegisterUrl();
      if (status === SUCCESS_STATUS) {
        window.open(data, '_self');
      } else {
        ElMessage.error(message || '获取注册地址失败');
      }
    };
    const getWorkBenchUrlFn = async () => {
      const { status, data, message } = await getWorkBenchUrl();
      if (status === SUCCESS_STATUS) {
        window.open(data, '_self');
      } else {
        ElMessage.error(message || '获取工作台地址失败');
      }
    };

    const handleLogout = async () => {
      // const expirationDate = new Date();
      // expirationDate.setDate(expirationDate.getDate() - 1);
      // document.cookie = `gsrm_front_token=;expires=${expirationDate.toUTCString()}`;
      // document.cookie =
      //   'gsrm_front_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
      // localStorage.clear();
      // dispatch(`${MACHINE}/${SET_MACHINE}`, {
      //   userInfo: {},
      //   isLogin: false,
      // });
      // location.reload();
      const tokenStr = localStorage.getItem('token') || '';
      if (tokenStr) {
        const { status, data } = await getLogoutUrl();
        if (status === SUCCESS_STATUS) {
          const url = data + `${window.location.origin}`;
          localStorage.removeItem('token');
          url && window.location.replace(url);
        } else {
          ElMessage.error('退出失败');
        }
      }
    };

    const userInfo = computed(() => state[MACHINE].userInfo);

    return () => (
      <div class="homepage-header-container">
        <header class="header-container">
          <div class="header-quick-navigate">
            <div class="d-flex justify-content-between pv-6 portal-app-content">
              <div class="d-flex align-items-center  white-space-nowrap">
                <p>您好，欢迎来到{pageData.value.platformName}</p>
                <div class="header-username">
                  {userInfo.value?.username || pageData.value?.loginUserName}
                </div>
              </div>
              <div class="header-quick-nav d-flex align-items-center overflow-auto">
                {quickBar.value?.map((item: IRecord, index: number) => (
                  <>
                    {index > 0 && <div class="halving-line"></div>}
                    <div class="header-quick-nav-item">
                      {item.logoPath && (
                        <img
                          class="header-quick-nav-icon"
                          src={item.logoPath}
                        />
                      )}
                      <p
                        onClick={() => {
                          if (!props.isCanvasReadonly) {
                            handlePush(item);
                          }
                        }}
                      >
                        {item.pathName}
                      </p>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          <div class="header-logo-login">
            <div class="d-flex align-items-center justify-content-between portal-app-content">
              <div class="d-flex align-items-center">
                {pageData.value.logoPath && (
                  <img class="header-logo-img" src={pageData.value.logoPath} />
                )}
                {pageData.value.useDefaultLogo && !pageData.value.logoPath && (
                  <img
                    class="header-logo-img"
                    src={require('@/assets/portal/footer.png')}
                    style={{ height: '30px' }}
                  />
                )}
                {/* <div class="header-title">{pageData.value.platformName}</div> */}
              </div>
              <div class="header-login-layout">
                <div class="d-flex">
                  <el-input
                    v-model={keyword.value}
                    placeholder="请输入搜索内容"
                    size="large"
                    readonly={props.isCanvasReadonly}
                    suffix-icon={
                      <div
                        class="cursor-pointer"
                        onClick={() => {
                          if (!props.isCanvasReadonly) {
                            router.push(
                              `${PORTAL_ROUTER_PATH.GLOBAL_SEARCH}?keyword=${keyword.value}`
                            );
                          }
                        }}
                      >
                        <Search />
                      </div>
                    }
                    onKeydown={(keyEvent: any) => {
                      if (!props.isCanvasReadonly) {
                        if (keyEvent.keyCode === 13) {
                          router.push(
                            `${PORTAL_ROUTER_PATH.GLOBAL_SEARCH}?keyword=${keyword.value}`
                          );
                        }
                      }
                    }}
                  />
                  {pageData.value.loginDisplay &&
                    !Object.values(userInfo.value || {}).length && (
                      <el-button
                        class="ml-20"
                        type="primary"
                        style={{ width: '70px' }}
                        disabled={props.isCanvasReadonly}
                        onClick={() => {
                          if (!props.isCanvasReadonly) {
                            window.open('/v9/base/oauth/prepare', '_self');
                          }
                        }}
                      >
                        登录
                      </el-button>
                    )}
                  {pageData.value.regDisplay &&
                    !Object.values(userInfo.value || {}).length && (
                      <el-button
                        disabled={props.isCanvasReadonly}
                        type="primary"
                        plain
                        style={{ width: '70px' }}
                        onClick={() => {
                          if (!props.isCanvasReadonly) {
                            getPortalRgstUrl();
                          }
                        }}
                      >
                        注册
                      </el-button>
                    )}
                  {!!Object.values(userInfo.value || {}).length && (
                    <el-button
                      type="primary"
                      plain
                      class="ml-20"
                      disabled={props.isCanvasReadonly}
                      onClick={() => {
                        if (!props.isCanvasReadonly) {
                          getWorkBenchUrlFn();
                        }
                      }}
                    >
                      工作台
                    </el-button>
                  )}
                  {!!Object.values(userInfo.value || {}).length && (
                    <el-button type="primary" plain onClick={handleLogout}>
                      退出
                    </el-button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  },
});
