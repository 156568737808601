import router from '@/router';
import NProgress from 'nprogress';
import 'nprogress/css/nprogress.css';

NProgress.configure({ showSpinner: false });

// 路由守卫
router.beforeEach(async (to) => {
  NProgress.start();
  // document.title = to.meta.title as string || '企业门户';
});

// 路由加载后；
router.afterEach(() => {
  NProgress.done();
});
