import request from '@/util/http';
import type { ApiDocPagesRequest, QuerySchemeRecord } from './type';

// 门户页面框架
export const getPageFrameData = (): Promise<ResponseResult> =>
  request.get('/v9/portal/conf/template/std/frame');

// 页面组件加载
export const getPageComponentData = (): Promise<ResponseResult> =>
  request.get('/v9/portal/conf/template/std/page');

// 门户首页，门户二级页面列表数据加载
export const getDocPagesByType = (
  data: ApiDocPagesRequest
): Promise<ResponseResult> =>
  request.post('/v9/ec/cms/document/std/docPages', data);

// 获取自定义地址的数据
export const customRequest = (
  url: string,
  params?: IRecord
): Promise<ResponseResult> => request.get(url, { params });

// 二级列表页面组件
export const getTemplateConfByCode = (params: {
  code: string;
}): Promise<ResponseResult> =>
  request.get('/v9/portal/conf/template/std/list', { params });

// 门户内容详情查看
export const getDocumentDetail = (data: {
  docId: string;
}): Promise<ResponseResult> =>
  request.post('/v9/portal/cms/document/std/findDoc', data);

// 门户内容详情组件
export const getDetailComponent = (params: {
  code: string;
}): Promise<ResponseResult> =>
  request.get('/v9/portal/conf/template/std/view', { params });

// 门户首页，门户全局搜索
export const querySearchList = (data: IRecord): Promise<ResponseResult> =>
  request.post('/v9/portal/cms/document/std/docPagesAll', data);

// 获取项管平台token
export const getToken = (): Promise<ResponseResult> =>
  request.get('/v9/base/oauth/pmpp/getToken');

// 获取用户信息
export const getUserInfo = (token: string): Promise<ResponseResult> =>
  request.get('/v9/base/oauth/pmpp/getUserInfo');

// 获取用户信息
export const getBidUrl = (params: IRecord): Promise<ResponseResult> =>
  request.get('/v9/portal/conf/bid/std/getBidUrl', { params });

// 获取关于我们
export const getAboutUs = (): Promise<ResponseResult> =>
  request.get('v9/portal/cms/document/std/getAboutUs');

// 预览
export async function previewFile(fileId: string, headers: IRecord) {
  return request({
    url: `/v9/spt/file/api/preview/${fileId}/url?tenantId=${headers['x-tenant-id']}&productCode=${headers['x-product-code']}&orgId=${headers['x-org-id']}`,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      ...headers,
    },
  });
}
export async function downloadFile(fileId: string, headers: IRecord) {
  return request({
    url: `/v9/spt/file/api/download/${fileId}/url?tenantId=${headers['x-tenant-id']}&productCode=${headers['x-product-code']}&orgId=${headers['x-org-id']}`,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      ...headers,
    },
  });
}

export const getObjTypeDict = (): Promise<ResponseResult> =>
  request.get('v9/base/module/std/dynamic-objType');

export const getProcType = (params: IRecord) => {
  return request.get('v9/biding/conf/std/proc-type-list', { params });
};
